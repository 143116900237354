/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.min.css";
@import "./assets/themes/light-theme.css";

/* import Quill styles */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

:root {
  --line-charts-background-1: #e3f2fd;
  --line-charts-color-1: #bbdefb;
  --line-charts-background-2: #e0f7fa;
  --line-charts-color-2: #b2ebf2;
  --line-charts-background-3: #e8eaf6;
  --line-charts-color-3: #c5cae9;
  --line-charts-background-4: #e0f2f1;
  --line-charts-color-4: #b2dfdb;
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), local("Nunito-Regular"), url(./assets/fonts/nunito-v12-latin-ext_latin-regular.84589feedfec9d37.woff2) format("woff2");
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  min-height: 100%;
  background: var(--surface-ground);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.row-content {
  width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
}

.large-icon {
  color: green;
  font-size: 36px;
  width: 36px;
  height: 36px;
}
